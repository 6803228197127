import React from "react";

const MaintenancePage = () => {
    return (
        <>
            <div className="wrapper">
                <section className="maintenance-wr">
                    <div className="container">
                        <div className="maintenance-content">
                        <div className="text-center fadeIn wow">
                            <div className="maintenance-heading">
                                <h1>Site Maintenance</h1>
                            </div>
                          <h3 className="warning-text">Our website is temporarily unavailable as we work to improve your experience. We'll be back online shortly. Thank you for your patience!</h3>
                          <h3 className="warning-text pt-4">For Urgent inquiries, please contact us at <a style={{ color: "#0A93D5" }}>support@ozgigs.com.au</a></h3>
                          <div>
                            <img className="maintenance-logo" src="/img/logo.svg" alt="ozgigs" />
                          </div>
                        </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default MaintenancePage;
